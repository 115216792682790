.uploaderContainer {
  position: relative;
  width: 90%;
  max-width: 500px;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: auto;
  scrollbar-width: none;
  margin: 0 auto; 
}
.progress {
  margin-top: 20px;
  width: 100%;
  height: 20px;
}
.progress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 1rem;
  
}
.progress::-webkit-progress-value {
  background-color: #16d877;
 border-radius: 1rem;
}

.progress::-moz-progress-bar {
  background-color: #485a46;
}
.backgp {
  position: relative;
  background-image: -webkit-radial-gradient(50% 100%, ellipse cover, rgba(19, 115, 250, 0.2) 50%, rgba(28, 45, 83, 0) 100%), -webkit-radial-gradient(100% 10%, ellipse cover, rgba(218, 166, 136, 0.6) 20%, rgba(30, 54, 23, 0.3) 80%), -webkit-linear-gradient(left top, #e6a733 1%, #ae8439 50%, #1d2541 100%);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh; 
  overflow: hidden;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.filePicker {
  background-color: #ffffff00;
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.fileInputLabel {
  color: #60eb0f;
  cursor: pointer;
}

.fileList {
  margin-top: 20px;
  overflow: hidden;

}

.fileList h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.fileList ul {
  list-style-type: none;
  padding: 0;
  height: 15rem;
  overflow: hidden;
  overflow-y: scroll;


}
.resultBox {
  background-color: black;
  border: 2px solid green;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
}



.textarea {
  width: 100%;
  height: 100px;
  background-color: black;
  color: white;
  border: none;
  font-family: 'Courier New', monospace;
  resize: none; 
  padding: 5px;
  margin-bottom: 10px;
}

.button {
  background-color: black;
  color: green;
  border: 1px solid green;
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  font-family: 'Courier New', monospace;
}

.button:hover {
  background-color: green;
  color: black;
}








.fileList li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #bea7be96;
  color: black;
  font-weight: 400;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.fileList li button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.uploadButton,
.clearButton {
  margin-top: 20px;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.uploadButton {
  background-color: #007bff;
  color: #fff;
}

.clearButton {
  background-color: #fc2121;
  color: #fff;
  float: right;
}

@media (min-width: 768px) {
  .uploaderContainer {
    width: 60%;
    padding: 2rem;
  }
}

@media (min-width: 1024px) {
  .uploaderContainer {
    width: 40%;
    padding: 3rem;
  }
}