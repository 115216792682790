:root {
    --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
    --orange: #fca61f;
    --black: #242d49;
    --gray: #788097;
    --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
    --pink: #FF919D;
    --glass: rgba(255, 255, 255, 0.54);
    --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
    --activeItem: #f799a354;
  }

*{
    margin: 0;
    padding: 0;

}
.body{
  color: var(--black);
  background: linear-gradient(
    106.37deg,
    #b6f6f8 29.63%,
    #d3f770 51.55%,
    #b17bae 90.85%
  );
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  overflow: auto;
}

.over {
    display: grid;
    height: 97%;
    width: 100vw;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    background: var(--glass);
    border-radius: 2rem;
    gap: 12px;
    grid-template-columns: 10rem auto 20rem;
  }
