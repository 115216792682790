.floating-icons-container {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #020912;
    height: 90vh;
    overflow: hidden;
	padding-top: 5rem;
  }
  
  .icon {
    filter: hue-rotate(120);
    position: absolute;
    transition: transform 0.5s ease;
    z-index:  -1;
  }
  
  .back {
    z-index: 100;
    filter: blur(2px);
    opacity: 0.6;
  }
  
  .mid {
    z-index: 200;
    filter: blur(1px);
    opacity: 0.8;
  }
  
  .front {
    z-index: 300;
  }

  .join-now-button {
    margin-top: auto;
    position: absolute;
    z-index: 700; 
  
  }
  


  @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap");

  .button-to-join {
	z-index: 700;
	  position: relative;
	  display: inline-block;
	  padding: 25px 30px;
	  margin: 40px 0;
	  color: #03f4c0;
	  text-decoration: none;
	  text-transform: uppercase;
	  transition: 0.5s;
	  letter-spacing: 4px;
	  overflow: hidden;
	  margin-right: 50px;
  }
  .button-to-join:hover {
	  background: #03f4c0;
	  color: #050801;
	  box-shadow: 0 0 5px #03f4c0, 0 0 25px #03f4c0, 0 0 50px #03f4c0,
	  0 0 200px #03f4c0;
	  -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
  }

  .button-to-join span {
	  position: absolute;
	  display: block;
  }
  .button-to-join span:nth-child(1) {
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 2px;
	  background: linear-gradient(90deg, transparent, #03f4c0);
	  animation: animate1 1s linear infinite;
  }
  @keyframes animate1 {
	  0% {
		  left: -100%;
	  }
	  50%,
	  100% {
		  left: 100%;
	  }
  }
  .button-to-join span:nth-child(2) {
	  top: -100%;
	  right: 0;
	  width: 2px;
	  height: 100%;
	  background: linear-gradient(180deg, transparent, #03f4c0);
	  animation: animate2 1s linear infinite;
	  animation-delay: 0.25s;
  }
  @keyframes animate2 {
	  0% {
		  top: -100%;
	  }
	  50%,
	  100% {
		  top: 100%;
	  }
  }
  .button-to-join span:nth-child(3) {
	  bottom: 0;
	  right: 0;
	  width: 100%;
	  height: 2px;
	  background: linear-gradient(270deg, transparent, #03f4c0);
	  animation: animate3 1s linear infinite;
	  animation-delay: 0.5s;
  }
  @keyframes animate3 {
	  0% {
		  right: -100%;
	  }
	  50%,
	  100% {
		  right: 100%;
	  }
  }

  .button-to-join span:nth-child(4) {
	  bottom: -100%;
	  left: 0;
	  width: 2px;
	  height: 100%;
	  background: linear-gradient(360deg, transparent, #03f4c0);
	  animation: animate4 1s linear infinite;
	  animation-delay: 0.75s;
  }
  @keyframes animate4 {
	  0% {
		  bottom: -100%;
	  }
	  50%,
	  100% {
		  bottom: 100%;
	  }
  }