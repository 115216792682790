.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #1a1a1a;
  color: #fff;
  font-family: 'Roboto', sans-serif;
}

.tab-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tab-button.active {
  background-color: #8c52ff;
}

.content-container {
  width: 80%;
  max-width: 800px;
  background-color: #2a2a2a;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.content {
  display: none;
}

.content.active {
  display: block;
}

.content-header {
  text-align: center;
  margin-bottom: 20px;
}

.content-header h2 {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.content-body {
  text-align: justify;
  line-height: 1.5;
  color: #ccc;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.game-button {
  background-color: #8c52ff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.game-button:hover {
  background-color: #6b21e8;
}