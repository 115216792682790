.body {
    display: flex;
    height: 100vh !important;
    position: relative;
    align-items: center;
    justify-content: center;
    font-family: sans-serif;
    line-height: 1.5;
    background-image: image-set("ok.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    flex-direction: column;
    user-select: none;
    margin: 0;
    z-index: 1;
}



#eye{
    
    filter: invert(1);
}

.spansg {
    display: flex;
    justify-content: space-between;
    width: 68%;
}

#turnl {
    text-align: right;
}
#turnr,
#turnl {
    text-decoration: none;
    display: block;
    width: fit-content;
    color: #f7763a;
    font-size: 25px;
}

#fp {
    text-decoration: none;
    display: block;
    width: fit-content;
    color: #f7763a;
    font-size: 25px;
    text-align: left;
}

.container {
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    width: 100%;
    height: 100%;
}


.Rotator {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: visible;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    transition: all 0.9s ease-in-out;
    transform: rotateY(0deg);
}

.main,
.main2 {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    padding: 20px;
    box-sizing: border-box;
}
.form {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}


.form{
    align-items: center;
    justify-content: center;
    display: flex; 
    flex-direction: column;
    
}


.main2 {
    transform: rotateY(180deg);
}

.h1sg {
    color: #c95335;
    font-size: 2.4rem;
}

.labelsg {
    display: block;
    font-size: 1.6rem;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 5px;
    text-align: left;
    color: #df8063;
    font-weight: bold;
}

.inputsg {
    display: block;
    width: 70%;
    height: 2.5rem;
    margin-bottom: 15px;
    color: #000000;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #e06c00;
    border-radius: 5px;
    font-size: 1.5rem;
}

.inputsg:focus {
    outline: 5px solid #32e4ae;
    border-radius: 20px;
    border-color: #000000;
    
}


.inputsg:hover{
   scale: 1.04;
    transition: 0.8s;
   
}


.inputsg::placeholder{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.5rem;
    color: #000000;
}


#signsub {
    padding: 15px;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: none;
    color: white;
    cursor: pointer;
    background-color: #f57135;
    width: 100%;
    padding: 5px 20px 5px 20px; 
    font-size: 2rem;
}


.hide-scrollbar {
    overflow: hidden;
    font-size: 30px;
    width: auto;
  }

.submit {
    display: flex;
    justify-content: center;
    align-items: center;
}

#signsub:hover {
    background-color: #47dd19;
   cursor: pointer;
}

@media screen and (max-width: 768px) {
    .container {
        width: 90vw;
    }
    #eye{
        /* calculate the previous margin left with redused size of form that is 80 vh */
        margin-left: calc(10% - 800vh);
    }
    .form {
        height: 80%;
        width: 80vh;
    }
    .main{
        height: 100vmax;
    }
    .h1sg{
        font-size: 40px;
    }

}


