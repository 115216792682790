@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
   
    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;  
  }

  .text-glow {
text-shadow: 0 0 10px rgba(255, 255, 255, 0.7), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 30px rgba(255, 255, 255, 0.3);
  }
}