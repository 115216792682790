@font-face { font-family: 'Keshiki';  src: url('Keshiki/Sdasian-WyDon.ttf');  }
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

#body{
   margin: 0;
   padding: 0;
  width: 100%;
  max-height: 100vh;
  scrollbar-width: none;
  -ms-overflow-style: none;
  }


.header{
font-family: 'Monsterrat',sans-serif;
background-color: black;
position: fixed;
top: 0;
left: 0;
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
transition: 0.6s ease;
padding: 25px 50px;
z-index: 1000;
}

.header.sticky {
  padding: 10px 50px;
  background-color: rgb(209, 209, 209);
}
.header.sticky .logo,
.header.sticky ul li a {
  color: black;
}
.logo{
  position: relative;
  font-family:Verdana, Geneva, Tahoma, sans-serif  ;
  font-weight: 700;
  color: rgb(103, 194, 103);
  text-decoration: none;
  font-size: 2em;

}
.menu{
position: relative;
display: flex;
justify-content: center;
align-items: center;
}

.menu li {
  position: relative;
  list-style: none;
}
.menu li a {
  position: relative;
  margin: 0 150px;
  color: aqua;
  letter-spacing: 2px;
  font-weight: 500;
 
}
.menu li a:hover{
  color:#34c8e6;

  border-radius: 50px;
  padding: 15px;
  border: 2px solid  #34c8e6;
  transition: 0.1s;
}
.menu li a:active{
color: greenyellow;
box-shadow: 0 0px 0px  #34c8e6 inset;
}
.main{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   min-height: 100vh;
   max-width: 100%;
}
#text{
position: fixed;
   font-family: 'Monsterrat',sans-serif;
   font-size: 3rem;
   font-weight: 700;
   color: aquamarine;
  
}


.paralax {
  
   min-width: 100%;
   max-height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   background-image: url('/public/sky.jpg');
  background-size: cover;
  background-attachment: fixed;
   background-repeat: no-repeat;
}

.paralax img{
  position: relative;
  left: 0;
  width: 100vw;
  pointer-events: none;
  height: auto;
  background-repeat: no-repeat;
  z-index: 20;
}
.sec{
  position: relative;
  max-width: 100vw;
  height: auto;
  background-color: antiquewhite;
  z-index: 20;
}