#body{
   display: flex;
   flex-direction: column;
   background-color: transparent;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    scrollbar-width:  none;
}
@media only screen and (max-width:570px){
    #body{
        display: flexbox;
    }
}